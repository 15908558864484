export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
};

export type IAdmin = {
  readonly __typename: 'Admin';
  readonly email: Scalars['String'];
  readonly id: Scalars['ID'];
};

export type IBook = {
  readonly __typename: 'Book';
  readonly authors: ReadonlyArray<Scalars['String']>;
  readonly etag?: Maybe<Scalars['String']>;
  readonly googleId?: Maybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly isbn: ReadonlyArray<Scalars['String']>;
  readonly listings: IPaginatedListings;
  readonly publishedAt?: Maybe<Scalars['Date']>;
  readonly subTitle?: Maybe<Scalars['String']>;
  readonly thumbnail?: Maybe<Scalars['String']>;
  readonly title: Scalars['String'];
};


export type IBookListingsArgs = {
  paginate?: InputMaybe<IPaginationInput>;
};

export type IGoogleBook = {
  readonly __typename: 'GoogleBook';
  readonly authors: ReadonlyArray<Scalars['String']>;
  readonly etag?: Maybe<Scalars['String']>;
  readonly googleId: Scalars['String'];
  readonly isbn: ReadonlyArray<Scalars['String']>;
  readonly possibleCovers: ReadonlyArray<Scalars['String']>;
  readonly publishedAt?: Maybe<Scalars['Date']>;
  readonly subTitle?: Maybe<Scalars['String']>;
  readonly thumbnail?: Maybe<Scalars['String']>;
  readonly title: Scalars['String'];
};

export type IListing = {
  readonly __typename: 'Listing';
  readonly book: IBook;
  readonly condition?: Maybe<ListingCondition>;
  readonly createdAt: Scalars['Date'];
  readonly description: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly price: Scalars['Int'];
  readonly saved?: Maybe<Scalars['Boolean']>;
  readonly school: ISchool;
  readonly soldAt?: Maybe<Scalars['Date']>;
  readonly soldPrice?: Maybe<Scalars['Int']>;
  readonly user?: Maybe<IUser>;
};

export enum ListingCondition {
  Acceptable = 'ACCEPTABLE',
  Good = 'GOOD',
  LikeNew = 'LIKE_NEW',
  New = 'NEW',
  VeryGood = 'VERY_GOOD'
}

export type IListingInput = {
  readonly condition?: InputMaybe<ListingCondition>;
  readonly coverIndex?: InputMaybe<Scalars['Int']>;
  readonly description: Scalars['String'];
  readonly googleId: Scalars['String'];
  readonly price: Scalars['Int'];
};

export type IMessage = {
  readonly __typename: 'Message';
  readonly content: Scalars['String'];
  readonly createdAt: Scalars['Date'];
  readonly from: IUser;
  readonly fromId: Scalars['ID'];
  readonly id: Scalars['ID'];
  readonly thread: IThread;
  readonly threadId: Scalars['ID'];
  readonly to: IUser;
  readonly toId: Scalars['ID'];
};

export type IMutation = {
  readonly __typename: 'Mutation';
  readonly confirm: Scalars['Boolean'];
  readonly createListing: IListing;
  readonly deleteListing?: Maybe<Scalars['Boolean']>;
  readonly login: ISystemUser;
  readonly logout: Scalars['Boolean'];
  readonly register: IUser;
  readonly requestResetPassword: Scalars['Boolean'];
  readonly resendConfirmEmail: Scalars['Boolean'];
  readonly resetPassword: Scalars['Boolean'];
  readonly saveListing: IListing;
  readonly sellListing: IListing;
  readonly sendMessage: IMessage;
  readonly setPrice: IListing;
  readonly setSchoolName: ISchool;
  readonly startThread: IThread;
  readonly toggleUserTrackable: IUser;
};


export type IMutationConfirmArgs = {
  code: Scalars['String'];
};


export type IMutationCreateListingArgs = {
  listing: IListingInput;
};


export type IMutationDeleteListingArgs = {
  id: Scalars['ID'];
};


export type IMutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  type: SystemUserType;
};


export type IMutationRegisterArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type IMutationRequestResetPasswordArgs = {
  email: Scalars['String'];
};


export type IMutationResendConfirmEmailArgs = {
  email: Scalars['String'];
};


export type IMutationResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type IMutationSaveListingArgs = {
  listingId: Scalars['ID'];
  saved: Scalars['Boolean'];
};


export type IMutationSellListingArgs = {
  listingId: Scalars['ID'];
  price: Scalars['Int'];
};


export type IMutationSendMessageArgs = {
  content: Scalars['String'];
  threadId: Scalars['ID'];
};


export type IMutationSetPriceArgs = {
  listingId: Scalars['ID'];
  price: Scalars['Int'];
};


export type IMutationSetSchoolNameArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type IMutationStartThreadArgs = {
  listingId: Scalars['ID'];
};

export type IPaginatedBooks = {
  readonly __typename: 'PaginatedBooks';
  readonly items: ReadonlyArray<IBook>;
  readonly totalCount: Scalars['Int'];
};

export type IPaginatedListings = {
  readonly __typename: 'PaginatedListings';
  readonly items: ReadonlyArray<IListing>;
  readonly totalCount: Scalars['Int'];
};

export type IPaginatedMessages = {
  readonly __typename: 'PaginatedMessages';
  readonly items: ReadonlyArray<IMessage>;
  readonly totalCount: Scalars['Int'];
};

export type IPaginatedThreads = {
  readonly __typename: 'PaginatedThreads';
  readonly items: ReadonlyArray<IThread>;
  readonly totalCount: Scalars['Int'];
};

export type IPaginationInput = {
  readonly limit?: InputMaybe<Scalars['Int']>;
  readonly offset?: InputMaybe<Scalars['Int']>;
};

export type IQuery = {
  readonly __typename: 'Query';
  readonly book?: Maybe<IBook>;
  readonly googleBook: IGoogleBook;
  readonly listing?: Maybe<IListing>;
  readonly me?: Maybe<ISystemUser>;
  readonly schools: ReadonlyArray<ISchool>;
  readonly search: IPaginatedListings;
  readonly searchBooks: IPaginatedBooks;
  readonly searchGoogle?: Maybe<ReadonlyArray<IGoogleBook>>;
  readonly sessions: ReadonlyArray<ISession>;
  readonly thread?: Maybe<IThread>;
  readonly top: IPaginatedListings;
  readonly users: ReadonlyArray<IUser>;
};


export type IQueryBookArgs = {
  id: Scalars['ID'];
};


export type IQueryGoogleBookArgs = {
  id: Scalars['ID'];
};


export type IQueryListingArgs = {
  id: Scalars['ID'];
};


export type IQuerySearchArgs = {
  maxPrice?: InputMaybe<Scalars['Int']>;
  minPrice?: InputMaybe<Scalars['Int']>;
  paginate?: InputMaybe<IPaginationInput>;
  query?: InputMaybe<Scalars['String']>;
};


export type IQuerySearchBooksArgs = {
  paginate?: InputMaybe<IPaginationInput>;
  query?: InputMaybe<Scalars['String']>;
};


export type IQuerySearchGoogleArgs = {
  query: Scalars['String'];
};


export type IQueryThreadArgs = {
  id: Scalars['ID'];
};


export type IQueryTopArgs = {
  paginate?: InputMaybe<IPaginationInput>;
};

export type ISchool = {
  readonly __typename: 'School';
  readonly domain: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type ISession = {
  readonly __typename: 'Session';
  readonly expiresAt: Scalars['Date'];
  readonly id: Scalars['ID'];
  readonly user: ISystemUser;
};

export type ISubscription = {
  readonly __typename: 'Subscription';
  readonly newMessage?: Maybe<IMessage>;
};

export type ISystemUser = IAdmin | IUser;

export enum SystemUserType {
  Admin = 'ADMIN',
  User = 'USER'
}

export type IThread = {
  readonly __typename: 'Thread';
  readonly buyer: IUser;
  readonly buyerId: Scalars['ID'];
  readonly id: Scalars['ID'];
  readonly lastMessage?: Maybe<IMessage>;
  readonly listing: IListing;
  readonly listingId: Scalars['ID'];
  readonly messages: IPaginatedMessages;
  readonly other: IUser;
  readonly otherId: Scalars['ID'];
  readonly seller: IUser;
  readonly sellerId: Scalars['ID'];
};


export type IThreadMessagesArgs = {
  paginate?: InputMaybe<IPaginationInput>;
};

export type IUser = {
  readonly __typename: 'User';
  readonly confirmedAt?: Maybe<Scalars['Date']>;
  readonly email: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly isTrackable: Scalars['Boolean'];
  readonly listings: ReadonlyArray<IListing>;
  readonly name?: Maybe<Scalars['String']>;
  readonly photo?: Maybe<Scalars['String']>;
  readonly recent: ReadonlyArray<IListing>;
  readonly saved: IPaginatedListings;
  readonly school: ISchool;
  /** @deprecated use school.name instead */
  readonly schoolName?: Maybe<Scalars['String']>;
  readonly threads: IPaginatedThreads;
};


export type IUserSavedArgs = {
  paginate?: InputMaybe<IPaginationInput>;
};


export type IUserThreadsArgs = {
  paginate?: InputMaybe<IPaginationInput>;
};

export type IUserInput = {
  readonly name: Scalars['String'];
};
